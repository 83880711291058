<template>
    <div>
        合格证列表
    </div>
</template>

<script>
export default {
    name:'CertificateList'
}
</script>